import React from "react";
import { connect } from "react-redux";
import SalesChannel from "../components/SalesChannel";
import selectSalesChannelsContainer from "../selectors/salesChannel";
import {
  getChannels,
  createChannel,
  updateChannel,
} from "../actions/salesChannel";

const SalesChannelContainer = (props) => <SalesChannel {...props} />;

const mapStateToProps = (state) => ({
  ...selectSalesChannelsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetChannels: () => {
    dispatch(getChannels());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelContainer);
