import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ReactTable from "react-table";
import StatusOverlay from "../StatusOverlay"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import { Chip, Box } from '@material-ui/core';
import Button from "../Button";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import { Tooltip } from "@material-ui/core";
import * as constants from "../../../constants";
import fetch from "../../util/api-ajax";

const defaultSalesChannel = {
  name: '',
  type: '',
  channel_codes: [],
  toll_free_numbers: [],
  promo_code: '',
  comission_type: '',
  minimum_flow_days: '',
  minimum_bills_paid: '',
  upfront_amount: '',
  residual_rate: '',
}

const UpdateMode = ({ openDialog, setOpenDialog, doFetchGetChannels, channel }) => {
  const [inputChannelCodes, setInputChannelCodes] = useState('');
  const [inputTollFreeNumber, setInputTollFreeNumber] = useState('');
  const [salesChannelInfo, setSalesChannelInfo] = useState(defaultSalesChannel)
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleKeyDown = (e, keyName) => {
    if (e.key === 'Enter' && (inputChannelCodes.trim() || inputTollFreeNumber.trim())) {
      e.preventDefault()
      if (keyName === 'channel_codes') {
        setSalesChannelInfo({ ...salesChannelInfo, [keyName]: [...salesChannelInfo[keyName], inputChannelCodes.trim()] })
        setInputChannelCodes('')
      } else {
        setSalesChannelInfo({ ...salesChannelInfo, [keyName]: [...salesChannelInfo[keyName], inputTollFreeNumber.trim()] })
        setInputTollFreeNumber('')
      }
    }
  }

  const handleDelete = (tagToDelete, keyName) => {
    setSalesChannelInfo({ ...salesChannelInfo, [keyName]: salesChannelInfo[keyName].filter((tag) => tag !== tagToDelete) })
  }

  const validate = (obj) => {
    return Object.entries(obj).every(([key, value]) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      } else if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    });
  }

  const clearForm = () => {
    setSalesChannelInfo(defaultSalesChannel)
    setErrorMessage(null)
  }

  const handleUpdate = async data => {
    if (validate(data)) {
      setDisable(true)
      await fetch(constants.SALES_CHANNEL_UPDATE, data)
        .then(async (res) => {
          setDisable(false)
          if (res.error) {
            setErrorMessage(res.error)
          } else {
            doFetchGetChannels({})
            setOpenDialog(false)
            clearForm()
          }
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setDisable(false)
        });
    }
  }

  useEffect(() => {
    if (channel) {
      setSalesChannelInfo({
        channel_codes: channel.channel_codes,
        toll_free_numbers: channel.toll_free_numbers,
        promo_code: channel.promo_code,
        comission_type: channel.commission.type,
        minimum_flow_days: channel.commission.parameters.minimum_flow_days,
        minimum_bills_paid: channel.commission.parameters.minimum_bills_paid,
        upfront_amount: channel.commission.parameters.upfront_amount,
        residual_rate: channel.commission.parameters.residual_rate,
      })
    } else {
      clearForm()
    }
  }, [channel])

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle style={{ marginRight: '20px', marginLeft: '20px' }}>
        Update Channel
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText style={{ marginRight: '20px', marginLeft: '20px' }}>
          <Grid container>
            <Grid item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <TextField
                    label="Channel Codes"
                    type="text"
                    size="small"
                    color="primary"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={inputChannelCodes}
                    onChange={(e) => setInputChannelCodes(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'channel_codes')}
                    placeholder="Press Enter to add tag"
                  />
                  <Box display="flex" flexWrap="wrap">
                    {salesChannelInfo.channel_codes.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDelete(tag, 'channel_codes')}
                        style={{ margin: '5px' }}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <TextField
                    label="Toll Free Numbers"
                    type="text"
                    size="small"
                    color="primary"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={inputTollFreeNumber}
                    onChange={(e) => setInputTollFreeNumber(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'toll_free_numbers')}
                    placeholder="Press Enter to add Toll Free Numbers"
                  />
                  <Box display="flex" flexWrap="wrap">
                    {salesChannelInfo.toll_free_numbers.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDelete(tag, 'toll_free_numbers')}
                        style={{ margin: '5px' }}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  label="Promo Code"
                  type="text"
                  size="small"
                  color="primary"
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'promo_code': e.target.value })}
                  value={salesChannelInfo.promo_code}
                  select
                >
                  <MenuItem value="default">Default</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <b>Comisssion</b>
              <Grid container>
                <Grid style={{ marginTop: '20px' }} item sm={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Comission Type"
                      type="text"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'comission_type': e.target.value })}
                      value={salesChannelInfo.comission_type}
                      select
                    >
                      <MenuItem value="UpfrontPlusResidual">UpfrontPlusResidual</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingRight: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Minimum Flow Days"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'minimum_flow_days': e.target.value })}
                      value={salesChannelInfo.minimum_flow_days}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingLeft: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Minimum Bills Paid"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'minimum_bills_paid': e.target.value })}
                      value={salesChannelInfo.minimum_bills_paid}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingRight: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Upfront Amount"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'upfront_amount': e.target.value })}
                      value={salesChannelInfo.upfront_amount}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingLeft: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Residual Rate"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'residual_rate': e.target.value })}
                      value={salesChannelInfo.residual_rate}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginRight: '20px', marginLeft: '20px' }}>
        <Button
          onClick={async e => {
            await handleUpdate({
              id: channel.id,
              channel_codes: salesChannelInfo.channel_codes,
              toll_free_numbers: salesChannelInfo.toll_free_numbers,
              promo_code: salesChannelInfo.promo_code,
              commission: {
                type: salesChannelInfo.comission_type,
                parameters: {
                  minimum_flow_days: parseInt(salesChannelInfo.minimum_flow_days),
                  minimum_bills_paid: parseInt(salesChannelInfo.minimum_bills_paid),
                  upfront_amount: parseFloat(salesChannelInfo.upfront_amount),
                  residual_rate: parseFloat(salesChannelInfo.residual_rate),
                }
              }
            })
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 5,
            marginBottom: 10,
          }}
          disabled={disable}
        >
          Update
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false)
            clearForm()
          }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CreateMode = ({ openDialog, setOpenDialog, doFetchGetChannels }) => {
  const [inputChannelCodes, setInputChannelCodes] = useState('');
  const [inputTollFreeNumber, setInputTollFreeNumber] = useState('');
  const [salesChannelInfo, setSalesChannelInfo] = useState(defaultSalesChannel)
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleKeyDown = (e, keyName) => {
    if (e.key === 'Enter' && (inputChannelCodes.trim() || inputTollFreeNumber.trim())) {
      e.preventDefault()
      if (keyName === 'channel_codes') {
        setSalesChannelInfo({ ...salesChannelInfo, [keyName]: [...salesChannelInfo[keyName], inputChannelCodes.trim()] })
        setInputChannelCodes('')
      } else {
        setSalesChannelInfo({ ...salesChannelInfo, [keyName]: [...salesChannelInfo[keyName], inputTollFreeNumber.trim()] })
        setInputTollFreeNumber('')
      }
    }
  }

  const handleDelete = (tagToDelete, keyName) => {
    setSalesChannelInfo({ ...salesChannelInfo, [keyName]: salesChannelInfo[keyName].filter((tag) => tag !== tagToDelete) })
  }

  const validate = (obj) => {
    return Object.entries(obj).every(([key, value]) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      } else if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    });
  }

  const clearForm = () => {
    setSalesChannelInfo(defaultSalesChannel)
    setErrorMessage(null)
  }

  const handleCreate = async data => {
    if (validate(data)) {
      setDisable(true)
      await fetch(constants.SALES_CHANNEL_CREATE, data)
        .then(async (res) => {
          setDisable(false)
          if (res.error) {
            setErrorMessage(res.error)
          } else {
            doFetchGetChannels({})
            setOpenDialog(false)
            clearForm()
          }
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setDisable(false)
        });
    }
  }

  useEffect(() => {
    clearForm()
  }, [])

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle style={{ marginRight: '20px', marginLeft: '20px' }}>
        Create Channel
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText style={{ marginRight: '20px', marginLeft: '20px' }}>
          <Grid container>
            <Grid item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  label="Name"
                  type="text"
                  size="small"
                  color="primary"
                  value={salesChannelInfo.name}
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'name': e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  label="Type"
                  type="text"
                  size="small"
                  color="primary"
                  style={{ width: '100%' }}
                  value={salesChannelInfo.type}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'type': e.target.value })}
                  placeholder="Select Channel Type"
                  select
                >
                  <MenuItem value="Broker">Broker</MenuItem>
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="Digital">Digital</MenuItem>
                  <MenuItem value="Partner">Partner</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <TextField
                    label="Channel Codes"
                    type="text"
                    size="small"
                    color="primary"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={inputChannelCodes}
                    onChange={(e) => setInputChannelCodes(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'channel_codes')}
                    placeholder="Press Enter to add tag"
                  />
                  <Box display="flex" flexWrap="wrap">
                    {salesChannelInfo.channel_codes.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDelete(tag, 'channel_codes')}
                        style={{ margin: '5px' }}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <TextField
                    label="Toll Free Numbers"
                    type="text"
                    size="small"
                    color="primary"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={inputTollFreeNumber}
                    onChange={(e) => setInputTollFreeNumber(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'toll_free_numbers')}
                    placeholder="Press Enter to add Toll Free Numbers"
                  />
                  <Box display="flex" flexWrap="wrap">
                    {salesChannelInfo.toll_free_numbers.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDelete(tag, 'toll_free_numbers')}
                        style={{ margin: '5px' }}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  label="Promo Code"
                  type="text"
                  size="small"
                  color="primary"
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'promo_code': e.target.value })}
                  value={salesChannelInfo.promo_code}
                  select
                >
                  <MenuItem value="default">Default</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid style={{ marginTop: '20px' }} item sm={12}>
              <b>Comisssion</b>
              <Grid container>
                <Grid style={{ marginTop: '20px' }} item sm={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Comission Type"
                      type="text"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'comission_type': e.target.value })}
                      value={salesChannelInfo.comission_type}
                      select
                    >
                      <MenuItem value="UpfrontPlusResidual">UpfrontPlusResidual</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingRight: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Minimum Flow Days"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'minimum_flow_days': e.target.value })}
                      value={salesChannelInfo.minimum_flow_days}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingLeft: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Minimum Bills Paid"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'minimum_bills_paid': e.target.value })}
                      value={salesChannelInfo.minimum_bills_paid}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingRight: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Upfront Amount"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'upfront_amount': e.target.value })}
                      value={salesChannelInfo.upfront_amount}
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ marginTop: '20px', paddingLeft: '10px' }} item sm={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                      label="Residual Rate"
                      type="number"
                      size="small"
                      color="primary"
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e) => setSalesChannelInfo({ ...salesChannelInfo, 'residual_rate': e.target.value })}
                      value={salesChannelInfo.residual_rate}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginRight: '20px', marginLeft: '20px' }}>
        <Button
          onClick={async e => await handleCreate({
            name: salesChannelInfo.name,
            type: salesChannelInfo.type,
            channel_codes: salesChannelInfo.channel_codes,
            toll_free_numbers: salesChannelInfo.toll_free_numbers,
            promo_code: salesChannelInfo.promo_code,
            commission: {
              type: salesChannelInfo.comission_type,
              parameters: {
                minimum_flow_days: parseInt(salesChannelInfo.minimum_flow_days),
                minimum_bills_paid: parseInt(salesChannelInfo.minimum_bills_paid),
                upfront_amount: parseFloat(salesChannelInfo.upfront_amount),
                residual_rate: parseFloat(salesChannelInfo.residual_rate),
              }
            }
          })}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 5,
            marginBottom: 10,
          }}
          disabled={disable}
        >
          Create
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false)
            clearForm()
          }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SalesChannel = props => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const { channels, doFetchGetChannels } = props;
  const columns = [
    {
      id: 'name',
      Header: 'Name',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.name}</div>)
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.type}</div>)
    },
    {
      id: 'promo_code',
      Header: 'Promo Code',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.promo_code}</div>)
    },
    {
      id: 'minimum_bills_paid',
      Header: 'Minimum Bills Paid',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.commission.parameters.minimum_bills_paid}</div>)
    },
    {
      id: 'minimum_flow_days',
      Header: 'Minimum Flow Days',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.commission.parameters.minimum_flow_days}</div>)
    },
    {
      id: 'residual_rate',
      Header: 'Residual Rate',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.commission.parameters.residual_rate}</div>)
    },
    {
      id: 'upfront_amount',
      Header: 'Upfront Amount',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.commission.parameters.upfront_amount}</div>)
    },
    {
      Header: '',
      id: 'commands',
      width: 120,
      accessor: (row) => (
        <Tooltip placement='top' title='Review Channel'>
          <Button
            onClick={async () => {
              setSelectedChannel(row)
              setOpenUpdateDialog(true)
            }}
            variant='contained'
            color='primary'
            disabled={row.deleted}
            style={{ marginRight: '5px' }}
          >
            Review
          </Button>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    doFetchGetChannels({});
  }, []);

  return (
    <div>
      <StatusOverlay />
      <Grid container>
        <Grid item sm={6}>
          <h4>Sales Channel</h4>
        </Grid>
        <Grid item sm={6}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenCreateDialog(true)}
            style={{ float: "right", margin: "24px 0 16px" }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <br />
      <ReactTable
        data={channels}
        columns={columns}
        className="-striped -highlight"
        defaultPageSize={10}
        defaultSortDesc
        filterable
      />
      <UpdateMode
        openDialog={openUpdateDialog}
        setOpenDialog={setOpenUpdateDialog}
        channel={selectedChannel}
        doFetchGetChannels={doFetchGetChannels}
      />
      <CreateMode
        openDialog={openCreateDialog}
        setOpenDialog={setOpenCreateDialog}
        doFetchGetChannels={doFetchGetChannels}
      />
    </div>
  );
}

SalesChannel.propTypes = {
  channels: PropTypes.array.isRequired,
  lmiTypes: PropTypes.array.isRequired,
};

export default SalesChannel