import * as constants from "../../constants";

const salesChannelInitState = {
  channels: [],
};

export function salesChannel(state = salesChannelInitState, action) {
  switch (action.type) {
    case constants.SALES_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        channels: action.payload.list
      }
    default:
      return state;
  }
}
